/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import "@fontsource-variable/oswald"; // Defaults to weight 400 with all styles included.

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

/* Start dark/light mode */
// Function to toggle the theme
function toggleTheme() {
    // Check if the dark class is present on the documentElement
    if (document.documentElement.classList.contains('dark')) {
        // If it is dark, switch to light by removing the dark class
        document.documentElement.classList.remove('dark');
        // Update localStorage
        localStorage.setItem('theme', 'light');
    } else {
        // If it is light, switch to dark by adding the dark class
        document.documentElement.classList.add('dark');
        // Update localStorage
        localStorage.setItem('theme', 'dark');
    }
}

// Get the button by its ID
const toggleButton = document.getElementById('darkLight');

// Add click event listener to the button
toggleButton.addEventListener('click', toggleTheme);

/* End dark/light mode */
